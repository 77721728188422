import { useCallback, useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const getItemFromLocalStorage = useCallback(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);

      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T>(() => {
    return getItemFromLocalStorage();
  });

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        let valueToStore;

        if (value instanceof Function) {
          const currentValue = getItemFromLocalStorage();
          valueToStore = value(currentValue);
        } else {
          valueToStore = value;
        }

        setStoredValue(valueToStore);
        if (typeof window !== 'undefined') {
          localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [getItemFromLocalStorage, key]
  );

  return [storedValue, setValue] as const;
};
