import NavigationComponent from './NavigationComponent';
import FooterComponent from './FooterComponent';
import React from 'react';
import HeaderMainFooterLayout from '../base/HeaderMainFooterLayout';
import HomeComponent from './HomeComponent';
import SpecialityComponent from './SpecialityComponent';
import MediaComponent from './MediaComponent';
import DiscountComponent from './DiscountComponent';
import ContactComponent from './ContactComponent';
import DataCenter from '../data/DataCenter';
import { some } from 'lodash';
import { useInteractionState } from '../../hooks/useInteractionState';

type Props = {
  menuItems: MenuItem[];
  configuration: Configuration;
};

const MainPageComponent = ({ menuItems, configuration }: Props) => {
  const { cart } = useInteractionState();

  const hasMenu = (items: MenuItem[], menuKey: string) => {
    return some(items, (it: MenuItem) => it.key === menuKey && it.show);
  };

  return (
    <HeaderMainFooterLayout
      header={
        <NavigationComponent
          menuItems={menuItems}
          cartCount={(cart ?? []).length}
        />
      }
      main={
        <div className="content">
          {hasMenu(menuItems, DataCenter.HOME_MENU) && (
            <HomeComponent configuration={configuration} />
          )}
          {hasMenu(menuItems, DataCenter.SPECIALITY_MENU) && (
            <SpecialityComponent />
          )}
          {hasMenu(menuItems, DataCenter.MEDIA_MENU) && <MediaComponent />}
          {hasMenu(menuItems, DataCenter.DISCOUNT_MENU) && (
            <DiscountComponent />
          )}
          {hasMenu(menuItems, DataCenter.CONTACT_MENU) && (
            <ContactComponent
              telephone={configuration.telephone}
              emailDisable={configuration.emailDisable}
            />
          )}
        </div>
      }
      footer={<FooterComponent />}
    />
  );
};

export default MainPageComponent;
