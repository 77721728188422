import { sendCartWithOrder } from './ApiService';
import { sumBy, find } from 'lodash';

class CartService {
  formatCurrency = (currency?: number, language?: string) => {
    return !currency
      ? 0
      : currency.toLocaleString(language, {
          style: 'currency',
          currency: 'EUR',
        });
  };

  sumWithDeliveryFee = (orders: OrderItem[], postalCode?: string) => {
    const price = sumBy(orders, (item: OrderItem) => {
      return item.price * item.quantity;
    });
    // const selfTaken = price && price < 30;
    // let distanceDeliveryFee = undefined;
    //
    // _.each(DataCenter.DELIVERY_PLZ, (values, key) => {
    //     if (values.indexOf(postalCode) > -1) {
    //         distanceDeliveryFee = DataCenter.DELIVERY_FEE_PLZ[key];
    //     }
    // });

    // const deliveryFee = selfTaken || !price ? 0 : distanceDeliveryFee;
    return {
      price: price,
      deliveryFee: undefined,
      selfTaken: true,
    };
  };

  addToCart = (
    item: SpecialityItem,
    cart?: OrderItem[],
    setCart?: Function
  ) => {
    const orders = cart ?? [];
    const order: OrderItem = {
      id: item.id,
      nameDe: item.nameDe,
      nameEn: item.nameEn,
      nameZn: item.nameZn,
      descriptionDe: item.descriptionDe,
      descriptionEn: item.descriptionEn,
      descriptionZn: item.descriptionZn,
      price: item.price,
      quantity: 1,
    };
    const found = find(orders, (it: OrderItem) => it.id === item.id);
    if (found) {
      found.quantity += 1;
    } else {
      orders.push(order);
    }
    setCart?.(orders);
  };

  sendCart = (cart: Cart) => {
    return sendCartWithOrder(cart);
  };
}

const cartService = new CartService();
export default cartService;
