import React from 'react';
import { ShopCart } from './ShopCart';

type CartWrapperProps = {
  menuItems: MenuItem[];
  configuration: Configuration;
};
const ShopCartWrapper = ({ menuItems, configuration }: CartWrapperProps) => {
  return (
    <ShopCart
      orderReference={(Math.random() * 10e16).toString(36)}
      menuItems={menuItems}
      configuration={configuration}
    />
  );
};
export default ShopCartWrapper;
