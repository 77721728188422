import React, { useEffect, useState } from 'react';
import { InternationalTranslationProvider } from './hooks/useInternationalTranslation';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainPageComponent from './modules/components/MainPageComponent';
import './css/responsive.css';
import DataCenter from './modules/data/DataCenter';
import ShopCartWrapper from './modules/components/cart/ShopCartWrapper';
import Login from './modules/components/admin/Login';
import { AdminWrapper } from './modules/components/admin/AdminWrapper';
import { each, filter } from 'lodash';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import VerifyToken from './modules/components/admin/VerifyToken';
import { useFetchData } from './modules/service/api';
import { useInteractionState } from './hooks/useInteractionState';

const Content = () => {
  const { data } = useFetchData('/api');
  const configuration = data ?? DataCenter.DEFAULT_CONFIGURATION;

  const { data: menuData } = useFetchData('/api/menu');
  const { orderEnable, setOrderEnable } = useInteractionState();

  const [menus, setMenus] = useState<MenuItem[]>([]);
  const [cartMenus, setCartMenus] = useState<MenuItem[] | undefined>([]);

  useEffect(() => {
    each(menuData, (it: MenuItem) => {
      it.navigateToMenu = it.show && it.key === DataCenter.LUNCH_MENU;
      it.navigateToCart =
        it.show &&
        it.key === DataCenter.CART_MENU &&
        it.key !== DataCenter.HOME_MENU;
      it.target = it.show && it.key === DataCenter.HOME_MENU ? '/' : '#';
      if (it.navigateToCart) {
        setOrderEnable?.(true);
      }
    });
    const cartMenuItems = filter(menuData, (it: MenuItem) => it.usedInCart);
    setCartMenus(cartMenuItems);
    setMenus(menuData ?? []);
  }, [menuData, setOrderEnable]);

  const SentryRoutes = withSentryReactRouterV6Routing(Routes);

  return (
    <InternationalTranslationProvider>
      <BrowserRouter>
        <SentryRoutes>
          <Route
            path="/"
            element={
              <MainPageComponent
                configuration={configuration}
                menuItems={menus}
              />
            }
          />
          {orderEnable && cartMenus && (
            <Route
              path="/cart"
              element={
                <ShopCartWrapper
                  menuItems={cartMenus}
                  configuration={configuration}
                />
              }
            />
          )}
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<AdminWrapper />} />
          <Route path="/verify-token" element={<VerifyToken />} />
          <Route path="*" element={<Navigate to="/" />} />
        </SentryRoutes>
      </BrowserRouter>
    </InternationalTranslationProvider>
  );
};

export default Content;
