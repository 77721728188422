import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IntlProvider } from 'react-intl';
import de from './translation/de.json';
import en from './translation/en.json';
import zn from './translation/zn.json';
import DataCenter from '../modules/data/DataCenter';
import { useInteractionState } from './useInteractionState';

const Context = React.createContext<InternationalStateProp>({});

export const InternationalTranslationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { language, setLanguage } = useInteractionState();
  const languageSetting = language
    ? language
    : navigator.language.split(/[-_]/)[0];

  const [local, setLocal] = useState<string>(languageSetting);
  const handleLanguageMessage = (language: string) => {
    switch (language) {
      case DataCenter.LANGUAGE.EN:
        return en;
      case DataCenter.LANGUAGE.ZH:
        return zn;
      default:
        return de;
    }
  };

  const switchToEnglish = useCallback(() => {
    setLanguage?.(DataCenter.LANGUAGE.EN);
    setLocal(DataCenter.LANGUAGE.EN);
  }, [setLanguage]);

  const switchToGerman = useCallback(() => {
    setLanguage?.(DataCenter.LANGUAGE.DE);
    setLocal(DataCenter.LANGUAGE.DE);
  }, [setLanguage]);

  const switchToChinese = useCallback(() => {
    setLanguage?.(DataCenter.LANGUAGE.ZH);
    setLocal(DataCenter.LANGUAGE.ZH);
  }, [setLanguage]);

  const value = useMemo(
    () => ({
      locale: local,
      messages: handleLanguageMessage(local),
      switchToEnglish,
      switchToGerman,
      switchToChinese,
    }),
    [local, switchToChinese, switchToEnglish, switchToGerman]
  );

  return (
    <Context.Provider value={value}>
      <IntlProvider
        key={value.locale}
        locale={value.locale}
        messages={value.messages}
        defaultLocale={DataCenter.LANGUAGE.DE}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export const useTranslation = (): InternationalStateProp => {
  return useContext(Context);
};
