import {
  deleteAllDocuments,
  sendConfiguration,
  sendDocument,
  sendEmailConfiguration,
} from './ApiService';

const uploadDocument = (data: FormData, token?: string) => {
  sendDocument(data, token).then((res: { statusText: any }) =>
    console.log(res.statusText)
  );
};

const clearAllFiles = (token?: string) => {
  deleteAllDocuments(token).then((res: any) => console.log(res));
};

const updateManagementInfo = (management: Management, token?: string) => {
  sendConfiguration(management, token).then((res: any) => console.log(res));
};

const updateEmailConfiguration = (
  emailConfiguration: EmailConfiguration,
  token?: string
) => {
  sendEmailConfiguration(emailConfiguration, token).then((res: any) =>
    console.log(res)
  );
};

export {
  uploadDocument,
  clearAllFiles,
  updateManagementInfo,
  updateEmailConfiguration,
};
