import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const API = import.meta.env.VITE_SERVER_URL;

export const fetchData = async (path: string, token?: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'X-ErpRealm-Header': import.meta.env.VITE_ERP_REALM,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const response = await fetch(`${API}${path}`, {
    method: 'GET',
    headers: headers,
  });
  return await response.json();
};

export const useFetchData = (path: string, token?: string) => {
  return useQuery({
    queryKey: ['path', path],
    queryFn: () => fetchData(path, token),
    enabled: !!path,
  });
};

export const postData = async (path: string, payload?: any, token?: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'X-ErpRealm-Header': import.meta.env.VITE_ERP_REALM,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const options = {
    method: 'POST',
    headers: headers,
    body: payload ? JSON.stringify(payload) : undefined,
  };

  const response = await fetch(`${API}${path}`, options);
  return await response.json();
};

export const useTransmitData = (
  path: string,
  payload?: any,
  token?: string
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => postData(path, payload, token),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['path', path] });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
