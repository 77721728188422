import React, { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Content from './Content';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { InteractionStateContextProvider } from './hooks/useInteractionState';

const enableSentry = import.meta.env.VITE_ENABLE_SENTRY === 'true';
const version = import.meta.env.VITE_VERSION;

if (enableSentry) {
  Sentry.init({
    dsn: 'https://7a88e4d1b0a700084662edf427b492e4@o4505580884525056.ingest.sentry.io/4505613953990656',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracePropagationTargets: ['localhost', 'http:www.xiang-restaurant.com'],
    release: `xiang-release-ui@v${version}`,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient();

const rootElement = document.getElementById('app');
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <StrictMode>
      <InteractionStateContextProvider>
        <QueryClientProvider client={queryClient}>
          <Content />
        </QueryClientProvider>
      </InteractionStateContextProvider>
    </StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
