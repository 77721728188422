import React, { useState } from 'react';
import '../../../css/Login.css';
import { useInteractionState } from '../../../hooks/useInteractionState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyToken } from '../../service/ApiService';

const VerifyToken = () => {
  const { setToken } = useInteractionState();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? '';
  const navigate = useNavigate();

  const [error, setError] = useState<string | undefined>();

  const verifyCurrentToken = async () => {
    try {
      if (token) {
        const result = await verifyToken(token);
        setToken?.({
          token: result.data.tokenValue,
          firstName: result.data.user.firstName,
          lastName: result.data.user.lastName,
          userName: result.data.user.username,
          email: result.data.user.email,
          roles: result.data.user.roles,
        });
        setError(undefined);
        navigate('/admin');
      }
    } catch (error) {
      setError('验证失败，请联系管理员');
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <h2>等待验证中</h2>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped active"
              role="progressbar"
              style={{ width: '90%' }}
            ></div>
          </div>
          <div className="input-group">
            <input
              type="text"
              disabled={true}
              className="form-control"
              value={token}
              aria-describedby="button-addon2"
            />
            <span className="input-group-btn">
              <button
                className="btn btn-primary"
                type="button"
                disabled={!token}
                id="button-addon2"
                onClick={() => verifyCurrentToken()}
              >
                发送
              </button>
            </span>
          </div>
          {error && <h4 className="label label-danger">{error}</h4>}
        </div>
      </div>
    </div>
  );
};

export default VerifyToken;
