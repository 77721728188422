import React from 'react';
import { FormattedMessage } from 'react-intl';
import DataCenter from '../data/DataCenter';
import { useTranslation } from '../../hooks/useInternationalTranslation';

const DiscountComponent = () => {
  const { locale } = useTranslation();
  const description_de =
    'Jedes Essen wird im Morgengrauen von Hand hergestellt, wobei nur das einfachste verwendet wird Zutaten, ' +
    'um Gerüche und Aromen hervorzuheben, die den ganzen Block ansprechen. Schauen Sie jederzeit vorbei und erleben Sie die ' +
    'Einfachheit am feinsten.';
  const description_en =
    'Each food is handmade at the crack of dawn, using only the simplest of ingredients to bring out ' +
    'smells and flavors that beckon the whole block. Stop by anytime and experience simplicity at its finest.';
  const description_zn =
    '每种食物都是在破晓时分手工制作的，仅使用最简单的配料制成，散发着整个街区气息的香气和风味。随时停下来，享受最好的简单体验。';

  const setDescription = (language?: string) => {
    switch (language) {
      case DataCenter.LANGUAGE.EN:
        return description_en;
      case DataCenter.LANGUAGE.ZH:
        return description_zn;
      default:
        return description_de;
    }
  };
  const description = setDescription(locale);

  return (
    <section id="discount" className="description_content">
      <div className="text-content container">
        <div className="col-md-6">
          <h1>
            <FormattedMessage id="app.speciality.title" />
          </h1>
          <div className="icon-hotdog fa-2x" />
          <p className="desc-text">{description}</p>
        </div>
        <div className="col-md-6">
          <ul className="image_box_story2">
            <div
              id="carousel-example-generic"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#carousel-example-generic"
                  data-slide-to="0"
                  className="active"
                />
                <li data-target="#carousel-example-generic" data-slide-to="1" />
                <li data-target="#carousel-example-generic" data-slide-to="2" />
              </ol>
              <div className="carousel-inner">
                <div className="item active">
                  <img src="/images/takeway/101.jpeg" alt="..." />
                  <div className="carousel-caption" />
                </div>
                <div className="item">
                  <img src="/images/takeway/102.jpeg" alt="..." />
                  <div className="carousel-caption" />
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DiscountComponent;
