import * as React from 'react';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { InteractState } from './type/interactState';
import { useLocalStorage } from './useLocalStorage';
import DataCenter from '../modules/data/DataCenter';

const interactionStateInitialValue: InteractState = {
  token: undefined,
  setToken: () => {},
  isAuthentication: false,
  language: undefined,
  setLanguage: () => {},
  cart: undefined,
  setCart: () => {},
  customer: DataCenter.DEFAULT_CUSTOMER,
  setCustomer: () => {},
  orderEnable: undefined,
  setOrderEnable: () => {},
};

const InteractionStateContext = createContext<InteractState>(
  interactionStateInitialValue
);

interface Props {
  children?: ReactNode;
}

export const InteractionStateContextProvider: FC<Props> = ({
  children,
}: Props) => {
  const [token, setToken] = useLocalStorage<LoginUser | null>(
    DataCenter.TOKEN_KEY,
    null
  );
  const [language, setLanguage] = useLocalStorage<string | undefined>(
    DataCenter.LANGUAGE_KEY,
    undefined
  );
  const [cart, setCart] = useLocalStorage<OrderItem[]>(DataCenter.CART_KEY, []);
  const [customer, setCustomer] = useLocalStorage<Customer>(
    DataCenter.CART_CUSTOMER_KEY,
    DataCenter.DEFAULT_CUSTOMER
  );
  const [orderEnable, setOrderEnable] = useState(false);
  const [isAuthentication, setIsAuthentication] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      setIsAuthentication(false);
    } else {
      setIsAuthentication(true);
    }
  }, [token]);

  const values = useMemo(
    () => ({
      token,
      setToken,
      isAuthentication,
      language,
      setLanguage,
      cart,
      setCart,
      customer,
      setCustomer,
      orderEnable,
      setOrderEnable,
    }),
    [
      token,
      setToken,
      isAuthentication,
      language,
      setLanguage,
      cart,
      setCart,
      customer,
      setCustomer,
      orderEnable,
      setOrderEnable,
    ]
  );
  return (
    <InteractionStateContext.Provider value={values}>
      {children}
    </InteractionStateContext.Provider>
  );
};

export const useInteractionState = (): InteractState => {
  return useContext(InteractionStateContext);
};
