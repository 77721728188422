import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import LOGO from './svg/logo.svg';
import '../../../css/Login.css';
import { getLoginToken } from '../../service/ApiService';
import { useInteractionState } from '../../../hooks/useInteractionState';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const { isAuthentication } = useInteractionState();
  if (isAuthentication) {
    return <Navigate to={{ pathname: '/admin' }} />;
  }

  const handleEmailSubmit = async () => {
    try {
      await getLoginToken(email);
      setError(false);
      setIsSubmit(true);
    } catch (error) {
      setError(true);
      setIsSubmit(false);
    }
  };

  return (
    <div className="App-header">
      <h2>欢迎登陆后台管理系统</h2>
      <img src={LOGO} className="App-logo" alt="Logo" />

      <div className="row">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="输入邮箱地址"
            required
            aria-label="输入邮箱地址"
            aria-describedby="button-addon2"
          />
          <span className="input-group-btn">
            <button
              className="btn btn-primary"
              type="button"
              disabled={!email}
              id="button-addon2"
              onClick={() => handleEmailSubmit()}
            >
              发送
            </button>
          </span>
        </div>
      </div>
      {error ? (
        <div className="label label-danger">
          <span>错误请联系管理员</span>
        </div>
      ) : (
        isSubmit && (
          <div className="label label-info">
            <span>登录链接已发送到邮箱，请检查您的邮箱</span>
          </div>
        )
      )}
    </div>
  );
};

export default Login;
