import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Admin } from './Admin';
import { useInteractionState } from '../../../hooks/useInteractionState';

export const AdminWrapper = () => {
  const location = useLocation();
  const { isAuthentication } = useInteractionState();

  if (!isAuthentication) {
    return <Navigate to={{ pathname: '/login' }} state={{ from: location }} />;
  }

  return <Admin />;
};
